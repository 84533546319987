import React from "react"
import { graphql, navigate } from "gatsby"
import { Container, Box } from "@theme-ui/components"
import Layout from "../components/layout"
import { getNewsPath } from "../utils/path"
import ArticlesList from "./articlesList"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import PageHero from "./pageHero"
import ReactPaginate from "react-paginate"
import { ChevronRight, ChevronLeft } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import { useBreakpointIndex } from "@theme-ui/match-media"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"
import VerticalLines from "../components/verticalLines"

const News = ({ data: { page, articles, site }, pageContext }) => {
  const primary = getColor(themeUiTheme, "primary")
  const light = getColor(themeUiTheme, "light")
  const breakpointIndex = useBreakpointIndex()

  const getLink = pageNumber => {
    if (pageNumber === 1 || pageNumber === 0) {
      return `/news/`
    } else {
      return `/news/${pageNumber}/`
    }
  }

  const locale = pageContext.locale
  const localeSuffix = locale === "it" ? "" : "/en"

  const currentPage = pageContext.currentPage

  const handleChange = pageNumber => {
    let selected = pageNumber.selected + 1
    if (selected === 1) {
      navigate(`${localeSuffix}/news/`)
    } else {
      navigate(`${localeSuffix}/news/${selected}/`)
    }
  }

  const { group, index, first, last, pageCount } = pageContext
  const previousUrl = index - 1 == 1 ? "/" : (index - 1).toString()
  const nextUrl = (index + 1).toString()

  const favicon = useFavicon().site.faviconMetaTags
  console.log("page",page,)
  const i18nPaths = page._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value: getNewsPath(path.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <VerticalLines />
      <PageHero page={page} image={page.heroImage} />
      <ArticlesList
        articles={articles.nodes}
        locale={page.locale}
        pageIndex={pageContext.currentPage}
      />
      <Container sx={{zIndex:4,}}>
        <Box
          sx={{
            zIndex:4,
            display: "flex",
            justifyContent: "center",
            ul: {
              display: "flex",
              pl: 0,
              listStyleType: "none",
              alignItems: "center",
              li: {
                border: "1px solid",
                borderColor: "primary",
                ml: ["-1px"],
                cursor: "pointer",
                a: {
                  py: [2],
                  px: [3],
                  svg: {
                    stroke: "primary",
                  },
                  textDecoration: "none",
                  color: "primary",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
                "&:hover, &.active": {
                  backgroundColor: "primary",
                  a: {
                    svg: {
                      stroke: "light",
                    },

                    color: "light",
                  },
                },
              },
            },
          }}
        >
          <Box sx={{position:"relative", zIndex:4, li:{minHeight:"42px", a:{
            display:"flex", height:"100%", width:"100%", alignItems:"center", justifyContent:"center", 
          }}}}>
            <ReactPaginate
              forcePage={pageContext.currentPage - 1}
              previousLabel={<ChevronLeft size={20} color="#F60000" />}
              nextLabel={<ChevronRight size={20} color="#F60000" />}
              breakLabel="..."
              pageCount={pageContext.numStoriesPages}
              marginPagesDisplayed={breakpointIndex > 1 ? 3 : 1}
              pageRangeDisplayed={breakpointIndex > 1 ? 3 : 1}
              onPageChange={handleChange}
              activeClassName={"active"}
              hrefBuilder={getLink}
            />
          </Box>
          {/* <Box sx={{ a: { textDecoration: "none" } }}>
            <Pagination
              prevPageText={<ChevronLeft size={20} color="#428dfc" />}
              nextPageText={<ChevronRight size={20} color="#428dfc" />}
              firstPageText={<ChevronsLeft size={20} color="#428dfc" />}
              lastPageText={<ChevronsRight size={20} color="#428dfc" />}
              hideDisabled
              hideNavigation
              activePage={pageContext.currentPage}
              itemsCountPerPage={pageContext.limit}
              totalItemsCount={pageContext.numStoriesPages * pageContext.limit}
              pageRangeDisplayed={8}
              onChange={handleChange}
              getPageUrl={getLink}
            />
          </Box> */}
        </Box>
      </Container>
    </Layout>
  )
}

export default News

export const query = graphql`
  query NewsListQuery($skip: Int!, $limit: Int!, $locale: String!) {
    page: datoCmsBlog {
      ...NewsDetails
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    articles: allDatoCmsArticle(
      filter: { locale: { eq: $locale }, title: { nin: "null" } }
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }

    site: datoCmsSite {
      locales
    }
  }

  fragment NewsDetails on DatoCmsBlog {
    id
    locale
    title
    _allSlugLocales {
      value
      locale
    }
    heroImage {
      desktop: gatsbyImageData(
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 40
          fit: "crop"
          ar: "16:5"
          h: "600"
        }
      )
      mobile: gatsbyImageData(
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 30
        }
      )
    }
    model {
      apiKey
    }
  }
`
