import React from "react"
import { Box, Container, Grid } from "@theme-ui/components"
import ArticleThumb from "../components/articleThumb"

const ReferencesList = ({ articles, pageIndex }) => {
  return (
    <Box sx={{position:"relative",zIndex:4}}>
      <Container  sx={{ py:[7,7,7,7], }}>
      <Grid  columns={["1fr","1fr 1fr","1fr 1fr 1fr"]} gap={[4,4]}>
      {articles.map((article, index) => (
        <Box sx={{  position: "relative", display: "block" }}>
            <ArticleThumb article={article} key={article.id} index={index} />
        </Box>
      ))}
      </Grid>
      </Container>
    </Box>
  )
}

export default ReferencesList
